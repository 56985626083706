.sub-main-trucash-technical{
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.sub-main-trucash-technical__sections{
    width: 80%;
    margin: auto;
    display: flex;
}

.sub-main-trucash-technical__section{
    width: 50%;
    height: 100%;
    padding: 10px;
}
.sub-main-trucash-technical__section img{
    width: 80%;
    display: block;
    margin: auto;
}

.sub-main-trucash-technical__section h1{
    text-align: center;
    margin-bottom: 50px;
    font-size: 50px;
}

.sub-main-trucash-technical__section h2{
    text-align: center;
    margin-bottom: 30px;
}

.sub-main-trucash-technical__section h3{
    text-align: center;
    margin-bottom: 20px;
}

.sub-main-trucash-technical__section p{
    text-align: center;
    margin-bottom: 17px;
    color: rgb(112, 112, 112);
}

@media (max-width:900px){
    .sub-main-trucash-technical__sections{
        flex-direction: column;
    }
    .sub-main-trucash-technical__section{
        width: 100%;
    }
}