@font-face {
  font-family: "Roboto Black";
  src: url("./assets/fonts/Roboto-Black.ttf");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("./assets/fonts/Roboto-Medium.ttf");
}
*{
  font-family: "Roboto-Medium";
}

/* Paleta de colores */
.main-color-background{
  background: rgb(250, 179, 48)!important;
}
.main-color-text{
  color: rgb(250, 179, 48)!important;
}
.active-link{
  color:rgb(255, 208, 0)!important;
}
.link-hover:hover{
  color: rgb(255, 208, 0)!important;
}

