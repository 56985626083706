.investment-main{
    width: 100%;
    background-image: url(../../../../../assets/images/backgrounds/bg_01.jpg);
    background-position: center;
    background-size: cover;
    padding-bottom: 150px;
    
}

.investment-main__title{
    width: 100%;
    height: auto;
    padding-top: 150px;
}

 

@media(max-width:660px){
    .investment-main__title{
        padding-top: 50px;
    }
    .investment-main__title h1{
        font-size: 40px!important;
    }
}

.investment-main__title h1{
    text-align: center;
    font-size: 50px;
}
.investment-main__decoration{
    width: 100px;
    height: 3px;
    margin: auto;
}
.investment-main__title p{
    padding-top: 20px;
    text-align: center;
}
.investment-main__packages{
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
}
.investment-main__package{
    width: 300px;
    height: auto;
    padding: 1rem;

}
@media (max-width:1150px){
    .investment-main__packages{
        
        width: 70%;
    }
    .investment-main__button{
        width: 70%!important;
        margin: auto;
    }
    .investment-main__package{
        width: auto!important;
        margin-bottom: 50px;
    }
}
@media (max-width:950px){
    .investment-main__packages{
        flex-direction: column;
    }
    
   
}
.investment-main__package img{
    width: 100%;
}
.investment-main__button{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
}
.investment-main__package button{
    width: 250px;
    display: inline-block;
    padding: 20px 0px 20px 0px;
    font-size: 18px;
    background: white;
    border-radius: 5px;
    color: rgb(88, 88, 88);
    cursor: pointer;
    border: none;
   
    margin: auto;

}

