.logo{
    width: 150px;
    margin-bottom: 10px;
}
.descripction{
    text-align: left;
}
@media (max-width:767px){
    .descripction{
        text-align: center;
    }
}

.text-center{
    width: 80%!important;
}
.footer-copyright{
    width: 100%!important;
}
.networks{
    font-size: 20px;
    
}

.netw{
    margin: 5px!important;
   
    font-size: 20px;
}





#no-margin {
    margin-top: 0!important;
}