.services-main{
    width: 100%;
    height: auto;
    background-image: url(../../../../../assets//images/backgrounds/service.jpg);
    background-position: center;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 70px;
}


.services-main__title{
    text-align: center;
    font-size: 70px;
    color:white;
}

.services-main__cards{
    width: 100%;
    height: auto;
    /* background: silver; */
    display: flex;
    justify-content: center;
    
}

.services-main__card{
  
width: 40%;
    margin: 20px;
    
   
}
@media (max-width:700px){
    .services-main__cards{
        flex-direction: column;
    }
    .services-main__card{
        width: 90%!important;
    }
}

.services-main__card img{
    width: 100%;
    margin: auto;
    border: 8px solid white;
}
.services-main__card p{
    width: 100%;
    text-align: center;
    margin: auto;
    font-size: 20px;
    color: white;
}
.services-main__card h2{
    font-size: 40px;
    text-align: center;
    padding-top: 30px;
}