.contact-info{
    padding-top: 50px;
    padding-bottom: 50px;
}

.contact-info h1{
    text-align: center;
    font-size: 40px;
}

.contact-info p{
    text-align: center;
    font-size: 18px;
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
    color: rgb(255, 255, 255);
}

.contact-info .divider{
    width: 60px;
    height: 2px;
    background: crimson;
    margin: auto;
    margin-bottom: 10px;
}

.img-leadership{
    width: 100%;
}