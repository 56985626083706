.ecosystem-main{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background-image: url(../../../../../assets/images/backgrounds/city-scaled.jpg);
    background-position: center;
    background-size: cover;
    padding-bottom: 50px;
}
.ecosystem-main__info{
    width: 40%;
    height: auto;
    padding-top: 80px;
}
.ecosystem-main__info h1{
    color: white;
    font-size: 50px;
}
.ecosystem-main__info p{
    color: rgb(185, 185, 185);
    font-size: 18px;
}
.ecosystem-main__img{
    width: 40%;
    height: auto;
}
.ecosystem-main__img img{
    width: 100%;
}
@media (max-width:850px){
    .ecosystem-main__img{
        display: none;
    }
    .ecosystem-main__info{
        width: 90%;
        padding-top: 30px;
    }
}

.ecosystem-particles{
    height: 500px;
    display: flex;
    position: absolute;
    z-index: 1;
    left: 0;
}