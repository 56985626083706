.why-choose-us-main{
    width: 100%;
    height: auto;
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: center;
    background-image: url(../../../../../assets/images/backgrounds/bg_02.jpg);
    background-position: center;
    background-size: cover;
}
.why-choose-us-main__content{
    width: 80%;
    height: auto;
    display: flex;
    z-index: 5;
 
  
    
}
.why-choose-us-main__content-img{
    height: auto;
    width: 50%;
}

@media(max-width:1240px){
    .why-choose-us-main__content-img{
        display: none!important;
    }
  
    .why-choose-us-main__content-info{
        width: 100%!important;
        padding: 0px!important;
    }
}
.why-choose-us-main__content-img img {
    width: 100%;
    z-index: 5;
}
.why-choose-us-main__content-info{
    padding-top: 50px;
    padding-left: 20px;
    width: 50%;
    height: auto;
}
.why-choose-us-main__content-info h1{
    font-size: 45px;
    color: white;
}
.why-choose-us-main__content-info p{
    font-size: 18px;
    color: white;

}
.why-choose-us-main__deco-bar{
    width: 50px;
    height: 2px;
    margin-bottom: 30px;
}

.why-choose-us-main__button-main{
    width: 100%;
    /* height: 100px; */
    display: flex;
    justify-content: flex-start;
   
}

.why-choose-us-main__button-main button{
    padding: 20px 40px 20px 40px;
    border:none;
    color: white;
    font-size: 17px;
    border-radius: 5px;
    font-weight: bold;
    -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  background: rgb(16, 170, 62);
}

.why-choose-us-main__button-main button:hover{
    background: rgb(148, 39, 39);
    cursor: pointer;
}


.about-particles{
    height: 500px;
    display: flex;
    position: absolute;
    z-index: 1;
    left: 0;
}