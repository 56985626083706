.sub-main-trucash-what-is {
  background-image: url(../../../../../assets/images/backgrounds/truchasfondo.jpg);
  background-position: top;
  background-size: cover;
  padding-top: 150px;
  padding-bottom: 50px;
  margin-top: -83px;
}

.sub-main-trucash-what-is__base-info {
  width: 80%;
  margin: auto;
}

.sub-main-trucash-what-is__info {
  width: 50%;
  height: 100%;
}
@media (max-width: 930px) {
  .sub-main-trucash-what-is__info {
    width: 100%;
  }
}
.sub-main-trucash-what-is__info h1 {
  text-align: center;
  color: white;
  font-size: 50px;
}

.sub-main-trucash-what-is__info h2 {
  text-align: center;
  color: white;
  font-size: 40px;
}

.sub-main-trucash-what-is__info p {
  text-align: justify;
  color: white;
  font-size: 18px;
}

.sub-main-trucash-what-is__info__btn {
  background: rgb(158, 28, 28);
  color: white;
  border: none;
  display: inline-block;
  margin: auto;
  padding: 10px;
  border-radius: 4px;
  text-decoration: none;
}

.sub-main-trucash-what-is__info__btn:hover {
  color: white;
}

@media (max-width: 485px) {
  .sub-main-trucash-what-is__info h1 {
    font-size: 40px;
  }
  .sub-main-trucash-what-is__info h2 {
    display: none;
  }
}
