.terms-of-service-description strong{
    display: block;
    font-size: 30px;
    padding: 10px;
    color: rgb(63, 63, 236);
}

.terms-of-service-description h1{
    display: block;
    font-size: 50px;
    padding: 10px;
}

.terms-of-service-description{
    width: 80%;
    margin: auto;
    padding-bottom: 50px;
    padding-top: 30px;
}
.terms-of-service-description .divider{
    width: 60px;
    height: 2px;
    background: crimson;
    margin-left: 10px;
    margin-bottom: 30px;
}
.terms-of-service-description p{
    padding-left: 10px;
    font-size: 12px;
    color: rgb(230, 230, 230);
}