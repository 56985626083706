.sub-main-trucash-ecosystem{
    background-image: url(../../../../../assets/images/backgrounds/Esquema-Moneda-Metodo-de-pago-blanco-1.jpg);
    background-position: center;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
}

.sub-main-trucash-ecosystem__base-info{
    width: 80%;
    margin: auto;

}

.sub-main-trucash-ecosystem__info{
    width: 50%;
    height: 100%;
}

@media (max-width:930px){
    .sub-main-trucash-ecosystem__info{
        width: 100%;
    }
}
.sub-main-trucash-ecosystem__info h1{
    text-align: center;
    color: white;
    font-size: 50px;
    padding-bottom: 20px;
}

.sub-main-trucash-ecosystem__info h2{
    text-align: center;
    color: white;
    font-size: 40px;

}

.sub-main-trucash-ecosystem__info p{
    text-align: justify;
    color: white;
    font-size: 18px;
}

.sub-main-trucash-ecosystem__info button{
    background: rgb(158, 28, 28);
    color: white;
    border: none;
    display: inline-block;
    margin: auto;
    padding: 10px;
    border-radius: 4px;
}

@media (max-width:485px){
    .sub-main-trucash-ecosystem__info h1{
        font-size: 40px;
    }
    .sub-main-trucash-ecosystem__info h2{
        display: none;
    }
}