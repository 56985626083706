.products-our{
    width: 100%;
  
    background-image: url(../../../../../assets/images/backgrounds/FOndo-Corporativo-marcos-copia.jpg);
    background-position: center;
    background-size: cover;
    padding-bottom: 150px;
}

.products-our__title h1{
    color:white;
    font-size: 50px;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 400px;
}

.products-our__info{
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: auto;
}

.products-our__section{
    
    /* background: green; */
    width: 33.3%;
    color: white;
}
@media (max-width:930px){
    .products-our__info{
        flex-direction: column;
    }
    .products-our{
        padding-bottom: 50px;
    }
    .products-our__section{
        padding-bottom: 0;
        /* background: green; */
        width: 100%;
        color: white;
    }
    .products-our__title h1{
        padding-top: 80px;
        padding-bottom: 500px;
    }
}

@media (max-width:930px){
    .products-our__title h1{
        padding-top: 80px;
        padding-bottom: 600px;
    }
}
.products-our__section h3, .products-our__section p{
    text-align: center;
    width: 90%;
    margin: auto;
}