.about-us-main {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}
.about-us-main__content {
  width: 80%;
  height: auto;
  display: flex;
  z-index: 5;
}
.about-us-main__content-img {
  height: auto;
  width: 50%;
}

@media (max-width: 1040px) {
  .about-us-main__content-img {
    display: none;
  }
  .about-us-main__content-info {
    width: 100% !important;
  }
}
.about-us-main__content-img img {
  width: 100%;
  z-index: 5;
}
.about-us-main__content-info {
  padding-top: 50px;
  padding-left: 20px;
  width: 50%;
  height: auto;
}
.about-us-main__content-info h1 {
  font-size: 45px;
}
.about-us-main__content-info p {
  font-size: 18px;
  color: gray;
}
.about-us-main__deco-bar {
  width: 50px;
  height: 2px;
  margin-bottom: 30px;
}

.about-us-main__button-main {
  width: 100%;
  /* height: 100px; */
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}
@media (max-width: 600px) {
  .about-us-main__button-main {
    justify-content: flex-start;
  }
}

.about-us-main__button-main button {
  padding: 20px 40px 20px 40px;
  border: none;
  color: rgba(26, 26, 26, 0.822);
  font-size: 17px;
  border-radius: 5px;
  font-weight: bold;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.about-us-main__button-main button:hover {
  background: rgb(148, 39, 39);
  cursor: pointer;
}

.about-particles {
  height: 500px;
  display: flex;
  position: absolute;
  z-index: 1;
  left: 0;
}

@media (max-width: 1040px) {
  .about-particles {
    display: none !important;
  }
}
