.leadership-description{
    padding-top: 50px;
    padding-bottom: 50px;
}

.leadership-description h1{
    text-align: center;
    font-size: 40px;
}

.leadership-description p{
    text-align: center;
    font-size: 18px;
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
    color: rgb(70, 70, 70);
}

.leadership-description .divider{
    width: 60px;
    height: 2px;
    background: crimson;
    margin: auto;
    margin-bottom: 10px;
}

.img-leadership{
    width: 100%;
}