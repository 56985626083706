.products-why-use{
    background-image: url(../../../../../assets/images/backgrounds/bien.jpg);
    background-position: center;
    background-size: cover;
    padding-bottom: 50px;
}

.products-why-use h2{
    text-align: center;
    padding-top: 50px;
    padding-bottom: 30px;
    color: white;
    font-size: 50px;
}
.products-why-use h3{
    text-align: center;
    color: white;
    font-size: 40px;
}
@media (max-width:550px){
    .products-why-use h3{
        font-size: 18px!important;
    }
}

.products-why-use img{
    width: 30px;
}