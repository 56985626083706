.referral-program-partners__title{
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}
.referral-program-partners__title h1{
    font-size: 50px;
    text-align: center;
}
.referral-program-partners__title p{
    text-align: center;
    padding-bottom: 50px;
}
.referral-program-partners__title strong{
    text-align: center;
    display: block;
    font-size: 20px;
    color: rgb(255, 255, 255);
}
.referral-program-partners__content{
    width: 80%;
    margin: auto;
    display: flex;
    padding-bottom: 50px;
}

.referral-program-partners__section{
    width: 50%;
}

.referral-program-partners__section img{
    width: 90%;
}
.referral-program-partners__section h1{
    font-size: 50px;
}

.referral-program-partners__section h4{
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: bold;
}
.referral-program-partners__section p{
    font-size: 17px;
    width: 90%;
    color: rgb(255, 255, 255);
}

.referral-program-partners__section .divider{
    width: 50px;
    height: 2px;
    background: brown;
    margin-top: 5px;
    margin-bottom: 10px;
}


@media (max-width:950px){
    .referral-program-partners__content{
        flex-direction: column;
    }
    .referral-program-partners__section{
        width: 100%;
    }
}