.about-us-third__objetives{
    width: 50%;
    height: auto;
    
}
.about-us-third__objetives img{
    width: 100%;
    display: flex;
    margin: auto;
    height: auto;
}
.about-us-third__info{
    padding-left: 10px;
   
}
.about-us-third__objetives p{
    font-size: 17px;
    color: gray;
    text-align: justify;
    width: 90%;
}
.about-us-third__objetives h1{
    font-size: 40px;
    padding-top: 20px;
    
}
.about-us-third__deco{
    width: 100px;
    height: 2px;
    background: rgb(52, 145, 221);
    margin-bottom: 20px;
    
}

.about-us-third__mission{
    width: 50%;
   
}
.about-us-third__mission img{
    width: 100%;
    display: flex;
    margin: auto;
    height: auto;
}
.about-us-third__mission p{
    font-size: 17px;
    color: gray;
    text-align: justify;
    width: 90%;
}
.about-us-third__mission h1{
    font-size: 40px;
    padding-top: 20px;
    
}
.about-us-third{
    padding-top: 50px;
    width: 80%;
    margin: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    padding-bottom: 50px;
}

@media(max-width:1000px){
    .about-us-third__mission{
        width: 100%;
    }
    .about-us-third__objetives{
        width: 100%;
    }
    .about-us-third{
        flex-direction: column;
    }
}