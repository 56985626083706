.about-us-first__info{
    width: 50%;
    height: auto;
    
}
@media(max-width:1000px){
    .about-us-first__info{
        width: 100%;
    }
    .about-us-first__info p{
        width: 100%!important;
    }
}
.about-us-first__info p{
    font-size: 17px;
    color: gray;
    text-align: justify;
    width: 90%;
}
.about-us-first__info h1{
    font-size: 40px;
    
}
.about-us-first__deco{
    width: 100px;
    height: 2px;
    background: rgb(52, 145, 221);
    margin-bottom: 20px;
}
.about-us-first__img{
    width: 50%;
    height: auto;
    /* display: flex; */
}
@media(max-width:1000px){
    .about-us-first__img{
        display: none;
    }
}
.about-us-first img{
    width:100%; 
}

.about-us-first{
    padding-top: 50px;
    width: 80%;
    margin: auto;
    height: auto;
    display: flex;
    flex-direction: row;
}