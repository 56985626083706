.about-us-our-services__info{
    width: 50%;
    height: auto;
    
}
.about-us-our-services__info p{
    font-size: 17px;
    color: gray;
    text-align: justify;
    width: 90%;
}
.about-us-our-services__info h1{
    font-size: 40px;
    
}
.about-us-our-services__deco{
    width: 100px;
    height: 2px;
    background: rgb(52, 145, 221);
    margin-bottom: 20px;
}
.about-us-our-services__img{
    width: 50%;
    /* display: flex; */
}
.about-us-our-services img{
    width: 90%;
}

.about-us-our-services{
    padding-top: 50px;
    width: 80%;
    margin: auto;
    height: auto;
    display: flex;
    flex-direction: row;
}

@media(max-width:1000px){
    .about-us-our-services__img{
        display: none;
    }
    .about-us-our-services__info{
        width: 100%;
    }
    .about-us-our-services__info p{
        width: 100%;
    }
}