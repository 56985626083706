.road-map-main h1{
    text-align: center;
    font-size: 50px;
    padding-top: 20px;
}
.road-map-main img{
    width: 80%;
    display: block;
    margin: auto;
}
@media (max-width:950px){
.road-map-main{
    display: none;
}
}