.sub-main__header {
  width: 100%;
  height: 800px;
  background-image: url(../../../../../assets/images/backgrounds/slider_onepage_05-1.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
}
.sub-main__header-text {
  width: 900px;
  color: white;
  margin: auto;
  font-size: 60px;
  text-align: center;
  margin-bottom: 20px;
}
@media (max-width: 1125px) {
  .sub-main__header-text {
    font-size: 40px;
    width: 90%;
  }
}
.sub-main__header-text--smaller {
  font-size: 35px;
}
.sub-main__header-text--smallest {
  font-size: 25px;
  text-align: center;
}
.sub-main__header-buttons {
  width: 500px;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.sub-main__header-button {
  padding: 20px 0px 20px 0px;
  border: none;
  width: 200px;
  border-radius: 3px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  margin: 20px;
  font-size: 16px;

  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  background: rgb(16, 170, 62);
  display: inline-block;
  text-align: center;
}
@media (max-width: 1125px) {
  .sub-main__header {
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (max-width: 600px) {
  .sub-main__header-buttons {
    width: 100%;
  }
  .sub-main__header-text {
    font-size: 25px;
  }
  .sub-main__header-button {
    width: 100%;
    margin: 0px;
    margin-bottom: 20px;
  }
  .sub-main__header {
    height: auto;
    padding-bottom: 50px;
  }
}
.sub-main__header-button:hover {
  background: rgb(148, 39, 39) !important;
  cursor: pointer;
  color: rgb(255, 255, 255);
}
