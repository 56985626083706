.info-header{
    width: 100%;
    height: 50px;
    background: rgb(26, 26, 26);
}
.info-header__content{
    display: flex;
    width: 95%;
    height: 100%;
    justify-self: center;
    justify-content: flex-end;
}
.info-header__item{
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
}
.info-header__text{
    font-size: 17px;
   
    padding-left: 10px;
    padding-right: 10px;
    color: white;
}

@media(max-width:600px){
    .info-header__text{
        font-size: 12px;
    }
}
@media(max-width:400px){
    .info-header__text{
        font-size: 10px;
    }
}