.referral-program-final-section img{
    width: 100%;
}

.referral-program-final-section{
    padding-bottom: 100px;
}

@media (max-width:850px){
    .referral-program-final-section{
        display: none;
    }
}