.trucash-token-main {
  width: 100%;
  height: auto;
  background-image: url(../../../../../assets/images/backgrounds/MOneda-callendo-lado-izquierdo-brillo-2000-x-600.jpg);
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 50px;
  padding-right: 10%;
}
@media (max-width: 1000px) {
  .trucash-token-main {
    justify-content: center;
  }
  .trucash-token-main__content-info {
    width: 90% !important;
  }
}
.trucash-token-main__content-info {
  padding-left: 20px;
  width: 40%;
  height: auto;
}
.trucash-token-main__content-info h1 {
  font-size: 45px;
  color: white;
  margin-top: 1em;
}
.trucash-token-main__content-info p {
  font-size: 18px;
  color: gray;
}
.trucash-token-main__deco-bar {
  width: 50px;
  height: 2px;
  margin-bottom: 30px;
}

.trucash-token-main__button-main {
  width: 100%;
  /* height: 100px; */
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.trucash-token-main__button-main a {
  padding: 20px 40px 20px 40px;
  border: none;
  font-size: 17px;
  border-radius: 5px;
  font-weight: bold;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  display: inline-block;
  text-align: center;
}

.trucash-token-main__button-main a:hover {
  background: rgb(148, 39, 39);
  cursor: pointer;
}
