.about-us-offerings{
    background-image: url(../../../../../assets/images/backgrounds/About-Us-BG.jpg);
    background-position: center;
    background-size: cover;
    padding-bottom: 50px;
}
.about-us-offerings h1{
    text-align: center;
    color: white;
    padding-top: 50px;
    font-size: 55px;
}

.about-us-offerings p{
    text-align: center;
    color: white;
    width: 70%;
    font-size: 18px;
    padding-top: 30px;
    margin: auto;
}