.privacy-policy-description strong{
    display: block;
    font-size: 30px;
    padding: 10px;
    color: rgb(63, 63, 236);
}

.privacy-policy-description h1{
    display: block;
    font-size: 50px;
    padding: 10px;
}

.privacy-policy-description{
    width: 80%;
    margin: auto;
    padding-bottom: 50px;
    padding-top: 30px;
}
.privacy-policy-description .divider{
    width: 60px;
    height: 2px;
    background: crimson;
    margin-left: 10px;
    margin-bottom: 30px;
}
.privacy-policy-description p{
    padding-left: 10px;
    font-size: 12px;
    color: rgb(255, 255, 255);
}