.sub-main-membership-description__title h1{
    text-align: center;
    font-size: 50px;
}

.sub-main-membership-description__title .divider{
    width: 60px;
    height: 2px;
    background: crimson;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}


.sub-main-membership-description__content i {
    color: #ce8200 !important;
}
.sub-main-membership-description__title p{
    text-align: center;
    width: 80%;
    margin: auto;
    font-size: 20px;
    color: gray;
}

.sub-main-membership-description__title{
    padding-top: 50px;
    padding-bottom: 50px;
}
.sub-main-membership-description__content{
    width: 80%;
    margin: auto;
    display: flex;
    padding-bottom: 50px;
}
.sub-main-membership-description__section{
    width: 50%;
    padding: 50px;
}

.sub-main-membership-description__content .divider{
    width: 60px;
    height: 2px;
    background: crimson;
    margin-top: 30px;
}

@media (max-width:950px){
    .sub-main-membership-description__content{
        flex-direction: column;
    }
    .sub-main-membership-description__section{
        width: 100%;
        padding: 0px;
    }
}