.get-started{
    width: 100%;
    display: flex;
    padding: 65px 0px 30px 0px;
    justify-content: center;
}
.get-started__text, .get-started__button{
    width: 40%;
}
.get-started__text{
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
}
.get-started__button{
    display: flex;
    align-items: center;
    justify-content: center;
}
.get-started__button button{
    padding: 25px 50px 25px 50px;
    font-size: 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background:  rgb(16, 170, 62)
}


@media (max-width:650px){
   
    .get-started{
        width: 100%;
        display: block;
        padding: 65px 0px 30px 0px;
    }
    .get-started__text, .get-started__button{
        width: 100%;
    }
    .get-started__text{
        font-size: 30px;
        width: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        height: 50px;
    }
    .get-started__button{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .get-started__button button{
        padding: 25px 50px 25px 50px;
        font-size: 20px;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        background:  rgb(16, 170, 62)
    }
}




.get-started-c{
    width: 100%;
    padding: 65px 0px 30px 0px;
}
.get-started-c__text, .get-started-c__button{
    width: 100%;
}
.get-started-c__text{
    font-size: 30px;
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    height: 50px;
}
.get-started-c__button{
    display: flex;
    align-items: center;
    justify-content: center;
}
.get-started-c__button button{
    padding: 25px 50px 25px 50px;
    font-size: 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background:  rgb(16, 170, 62)
}