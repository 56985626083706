.navbar-header{
    width: 100%;
    height: 70px;
    background: rgb(44, 44, 44);
    display: flex;
    justify-content: center;
   
    z-index: 999999!important;
}

.navbar-header__content{
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
   
    
}
.navbar-header__logo{
    height: 100%;
    width: 210px;
    display: flex;
    align-items: center;
}
.navbar-header__logo img{
    height: 70%;
}
.navbar-header__menu{
    width: calc(100% - 212px);
   
}

.navbar-header__list{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
}
.navbar-header__list-item{
    list-style: none;
    height: 100%;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
   
}
@media (max-width: 1080px){
    .navbar-header__list{
        flex-direction: column;
        height: auto;
        background: rgb(32, 32, 32);
        padding: 50px;
        padding-left: 0;
    }
    a{
        padding-left: 5px;
    }
}

.navbar-header__list-item-text{
    display: flex;
    align-self: center;
    text-decoration: none;
    font-size: 17px;
    color: white;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    background: none;
    border: none;
}

.navbar-header2{
    width: 100%;
    height: 200px;
    background: gray;
}